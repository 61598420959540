import React, { useState, useEffect } from 'react'
import Input from './../../../input/Input';
import SelectItems from '../../../select-items/SelectItems';
import axios from 'axios';
import ApiUrl from '../../../../services/ApiUrl';
import { useSelector } from 'react-redux';
import Editor from '../../../editor/Editor';

let validateNumber = 0;
const Collections = ( props ) => {
    let [ load, setLoad ] = useState(false);
    let [ error, setErrors ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let lang = props.langID

    useEffect(() => {
        if(!load && !props.edit) {
            props.onChange({
                text: "",
                collections: [],
            }, props.index);
            setLoad(true);
        }
        if(!load) {
            validateNumber = props.validate;
        }
    }, [load])

    useEffect(() => {
        if(!load && props.edit) {
            if(!props.data || Object.keys(props.data).length == 0) {
                props.onChange({
                    text: "",
                    collections: [],
                }, props.index);
            }
            setLoad(true);
        }
    }, [load])

    useEffect(() => {
        if(props.validate !== validateNumber) {
            validate();
        }
    }, [props.validate])

    const validate = async() => {
        await setForceValidate(true);
        await wait(100);
        await setForceValidate(false);
        let errors = await checkErrors();
        if(errors) {
            setErrors(true);
        }else{
            setErrors(false);
        }
    }

    const updateValue = (val, label) => {
        let newData = props.data;
        newData[label] = val;
        props.onChange({
            newData
        })
    }

    const saveData = (data) => {
        props.onChange({
            ...props.data,
            links: data
        }, props.index)
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll(`.collections-list-${props.index} .error`);
        if( elements.length > 0 ) {
            result = true;
        } else {
            result = false;
        }
        return result;
    }
    console.log(props.data.collections)
    return (
        <div className={error ? `section-wrapper collections-list error-section collections-list-${props.index}` : `section-wrapper collections-list collections-list-${props.index}`}>
            <div className="row-section">
                <Editor required={true} forceValidate={ forceValidate } setFunction={ val => updateValue(val, 'text') } value={props.data.text} label="Treść" />
            </div>
            <div className="row-section">
                <div className="select-boxes">
                    <SelectItems
                        label="Kolekcje"
                        value={ props.data.collections }
                        handle={ (val) => updateValue(val, 'collections') } 
                        // forceValidate={ forceValidate } 
                        langID={lang}
                        postType="collections"
                        column="xs-12" />
                </div>
            </div>
        </div>
    );
}

export default Collections;