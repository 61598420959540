import React, { useState, useEffect, useRef } from 'react'
import Input from '../../../components/input/Input';
import axios from 'axios';
import ApiUrl from '../../../services/ApiUrl';
import { useSelector, useDispatch } from 'react-redux'
import PageTitle from '../../../components/page-title/PageTitle';
import Button from '../../../components/button/Button';
import readTextNvda from '../../../services/readTextNvda';
import SaveIcon from '../../../svg-components/SaveIcon';
import SelectItems from '../../../components/select-items/SelectItems';

const CategoryItem = (props) => {
    const dispatch = useDispatch();
    let [ load, setLoad ] = useState(false);
    let [ blockedBtn, setBlockedBtn ] = useState(true);
    let [ title, setTitle ] = useState('');
    let pageID = typeof window !== "undefined" && (window.location.pathname).split("/");pageID = pageID[pageID.length -1]
    let [ resultPage, setResultPage ] = useState(false);
    let [ forceValidate, setForceValidate ] = useState(false);
    let [ marketings, setMarketings ] = useState([]);

    useEffect(() => {
        if(!load) {
            getPage();
            setLoad(true);
        }
    }, [load])
    useEffect(() => {
        if(load) {
            getPage();
            setLoad(false);
        }
    }, [pageID])

    const getPage = async() => {
        axios({
            method: 'post',
            url: `${ApiUrl()}/getMarketingsByCategory`,
            data: {
                token: localStorage.getItem('token'),
                pageID: pageID
            }
        }).then(response => {
            let res = JSON.parse(response.request.response);
            console.log(res)
            setTitle(res.name);
            setResultPage(res);
            setMarketings(res.marketings);
            setBlockedBtn(false);
        })
    }

    let validateForm = async() => {
        await setForceValidate(true);
        await wait(300);
        await setForceValidate(false);
        let errors = await checkErrors();
        if ( !errors ) {
            await save();
        } 
    }

    async function wait(ms) {
        return new Promise(resolve => {
          setTimeout(resolve, ms);
        });
    }

    let checkErrors = () => {
        let result;
        let elements = document.querySelectorAll('.sections-component .error');
        let elementsCard = document.querySelectorAll('.page-container .error');

        if( elements.length > 0 ) {
            elements[0].closest('.content').classList.add("active");
            readTextNvda("sections");
            let topElement = elements[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else if(elementsCard.length > 0) {
            readTextNvda("page");
            let topElement = elementsCard[0].getBoundingClientRect().top;
            let windowScroll = window.scrollY;
            window.scroll({
                behavior: 'smooth',
                left: 0,
                top: windowScroll + topElement
              });
            result = true;
        } else {
            result = false;
        }
        return result;
    }

    const save = async () => {
        if(!blockedBtn) {
            setBlockedBtn(true);
            axios({
                method: 'post',
                url: `${ApiUrl()}/saveMarketingsGroup`,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                data: {
                    token: localStorage.getItem('token'),
                    userID: localStorage.getItem('userId'),
                    pageID: pageID,
                    marketings: marketings
                }
            }).then(response => {
                let res = JSON.parse(response.request.response);
                if(res.status = "success") {
                    // setBlockedBtn(false);
                    props.history.push(`/dashboard/categories/page/${pageID}`)
                    setLoad(false);
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Zapisano</p>` }) 
                }else{
                    dispatch({ type: 'NOTIFICATION_ALERT_TEXT', value: `<p>Coś poszło nie tak, prosimy spróbować ponownie później</p>` }) 
                }
            })
        }
    }

    let keyPressed = (e, action) => {
        if ( e.code === "Enter" ) {
            action();
        }
    }

    console.log(marketings);

    return (
        <div className="container page-container">
            <PageTitle title="Edytuj Kategorię" />
            <div className="wrapper">
                <div className="form">
                    <div className="input-row">
                        <Input  name="title"
                            required={ true }
                            label="Tytuł strony"
                            type="text" 
                            value={ title }
                            disabled={ true } 
                            setFunction={ (val) => false } 
                            forceValidate={ forceValidate }
                            class="btn-primary btn-simple" />
                    </div>
                    {marketings &&
                        <div className="select-boxes">
                            <SelectItems
                                label="Kolory produktu"
                                disableLeftCol
                                value={ marketings }
                                handle={ (val) => setMarketings(val) } 
                                langID={resultPage.language} 
                                forceValidate={ forceValidate } 
                                column="xs-12" />
                        </div>
                    }
                    <div className="input-row save-row">
                        <Button title="Zapisz" setFunction={() => validateForm() } class={ `${blockedBtn ? ' disabled' : ''}` } />
                    </div>
                </div>
                <div className="boxes">
                    <div className={ `btn-fix ${blockedBtn ? 'disabled' : ''}` } tabIndex="0" aria-label="Zapisz strone" onClick={() => validateForm()} onKeyDown={(e) => keyPressed(e, validateForm)}>
                        <SaveIcon />
                    </div>
                </div>
            </div>
        </div>    
    );
}

export default CategoryItem;
